<template>
  <div>
    <el-form :model="test">
      <el-row :gutter="20">
        <el-col :md="3">
          <h3 class="primaryColor">{{ test.name.toUpperCase() }}:</h3>
        </el-col>

        <el-col :md="8">
          <el-select
            v-model="test.items.find(x => x.id === 0).subject"
            filterable
            @change="emitToParent"
            placeholder="Subject"
            style="width:100%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>

        <el-col :md="8">
          <el-form-item label="" :error="scoreError">
            <el-input
              v-model="test.items.find(x => x.id === 0).score"
              placeholder="Score"
              @input="emitToParent"
              @blur="checkScoreError(test, 0)"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="5" class="">
          <el-button
            class="dynamic"
            @click="add('DynamicTestChild')"
            icon="el-icon-plus"
            plain
          ></el-button>
        </el-col>
      </el-row>

      <el-row :gutter="20" v-if="!isTeacher">
        <!-- <el-col :md="2" class="d-none d-md-block">
          <el-form-item></el-form-item>
        </el-col> -->

        <el-col :span="14" :sm="8" :md="12">
          <el-form-item label="Is this a real test?">
            <el-select
              v-model="test.items.find(x => x.id === 0).is_real_test"
              @change="emitToParent"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="10" :sm="8" :md="12">
          <el-form-item label="Date of test">
            <el-date-picker
              v-model="test.items.find(x => x.id === 0).taken_on"
              type="date"
              @change="emitToParent"
              placeholder="Select the date"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <component
      v-for="item in test.items"
      :is="item.component"
      :key="item.id"
      :test="test"
      :item="item"
      :pushable_id="item.id"
      :isTeacher="isTeacher"
      @remove="del"
      @dataFromChild="update"
    ></component>
  </div>
</template>

<script>
import DynamicTestChild from "@/components/test/DynamicTestChild";
import testValidationRules from "@/mixins/testValidationRules";
import subjectsData from "@/data/subjects.json";
export default {
  props: ["test", "id", "isTeacher"],
  mixins: [testValidationRules],
  components: {
    DynamicTestChild
  },
  data() {
    return {
      subjectId: this.id,
      scoreError: "",
      isRealTestOptions: [
        {
          value: 1,
          label: "Yes"
        },
        {
          value: 0,
          label: "No"
        }
      ],
      options: this.getOptions()
    };
  },
  methods: {
    getOptions() {
      const subjects = subjectsData.find(obj => {
        return obj.label === this.test.value;
      });
      return subjects.options;
    },
    add: function(component) {
      this.test.items.push({
        id: this.subjectId,
        component: component,
        subject: "",
        score: "",
        taken_on: "",
        is_real_test: 1
      });

      this.subjectId++;
    },
    del: function(id) {
      let index = this.test.items.findIndex(obj => obj.id === id);
      this.test.items.splice(index, 1);
    },
    update(value) {
      const objIndex = this.test.items.findIndex(obj => obj.id === value.id);
      this.$set(this.test.items, objIndex, value);
      this.$emit("dataFromGrandchild", this.test);
    },
    emitToParent() {
      this.$emit("dataFromChild", this.test);
    }
  }
};
</script>

<style scoped>
.primaryColor {
  color: #42a16a;
}
.dynamic:focus {
  outline: none;
}
::v-deep .el-form-item{
  margin: 0;
}
</style>
