/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  computed: {
    ...mapState("user", ["lang"]),

    testRules() {
      let checkSatReadingGrammar = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 200 && parseInt(value) <= 800;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 200~800"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkSatEnglishMath = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 200 && parseInt(value) <= 800;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 200~800"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkSatEssay = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 0 && parseInt(value) <= 24;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 0~24"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkActSubjects = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 0 && parseInt(value) <= 36;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 0~36"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkActEssay = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 0 && parseInt(value) <= 12;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 0~12"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkToefl = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 0 && parseInt(value) <= 30;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 0~30"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkIelts = (rule, value, callback) => {
        const numReg = /^\d+(\.5)?$/;
        let v = parseFloat(value) >= 0 && parseFloat(value) <= 9;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 0~9"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkAP = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 0 && parseInt(value) <= 5;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 0~5"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      let checkIB = (rule, value, callback) => {
        const numReg = /^\d+$/;
        let v = parseInt(value) >= 1 && parseInt(value) <= 7;
        if (!value) callback();
        setTimeout(() => {
          if (!numReg.test(value)) {
            callback(new Error(this.$t("tests.invalidScore")));
          } else {
            if (!v) {
              callback(new Error(this.$t("tests.pleaseEnter") + " 1~7"));
            } else {
              callback();
            }
          }
        }, 100);
      };
      return {
        psatReading: [{ validator: checkSatReadingGrammar, trigger: "blur" }],
        psatGrammar: [{ validator: checkSatReadingGrammar, trigger: "blur" }],
        psatMath: [{ validator: checkSatEnglishMath, trigger: "blur" }],
        satReading: [{ validator: checkSatReadingGrammar, trigger: "blur" }],
        satGrammar: [{ validator: checkSatReadingGrammar, trigger: "blur" }],
        satEnglish: [{ validator: checkSatEnglishMath, trigger: "blur" }],
        satMath: [{ validator: checkSatEnglishMath, trigger: "blur" }],
        satEssay: [{ validator: checkSatEssay, trigger: "blur" }],
        actEnglish: [{ validator: checkActSubjects, trigger: "blur" }],
        actMath: [{ validator: checkActSubjects, trigger: "blur" }],
        actScience: [{ validator: checkActSubjects, trigger: "blur" }],
        actReading: [{ validator: checkActSubjects, trigger: "blur" }],
        actEssay: [{ validator: checkActEssay, trigger: "blur" }],
        toeflReading: [{ validator: checkToefl, trigger: "blur" }],
        toeflListening: [{ validator: checkToefl, trigger: "blur" }],
        toeflSpeaking: [{ validator: checkToefl, trigger: "blur" }],
        toeflWriting: [{ validator: checkToefl, trigger: "blur" }],
        ieltsReading: [{ validator: checkIelts, trigger: "blur" }],
        ieltsListening: [{ validator: checkIelts, trigger: "blur" }],
        ieltsSpeaking: [{ validator: checkIelts, trigger: "blur" }],
        ieltsWriting: [{ validator: checkIelts, trigger: "blur" }],
        ap: [{ validator: checkAP, trigger: "blur" }],
        ib: [{ validator: checkIB, trigger: "blur" }]
      };
    }
  },
  methods: {
    checkScoreError(test, curId) {
      const value = test.items.find(x => x.id === curId).score;
      const numReg = /^\d+$/;
      if (test.name === "AP") {
        let v = parseInt(value) >= 0 && parseInt(value) <= 5;
        if (!value) {
          this.scoreError = "";
        } else if (!numReg.test(value)) {
          this.scoreError = this.$t("tests.invalidScore");
        } else if (!v) {
          this.scoreError = this.$t("tests.pleaseEnter") + " 0~5";
        } else {
          this.scoreError = "";
        }
      } else if (test.name === "IB") {
        let v = parseInt(value) >= 1 && parseInt(value) <= 7;
        if (!value) {
          this.scoreError = "";
        } else if (!numReg.test(value)) {
          this.scoreError = this.$t("tests.invalidScore");
        } else if (!v) {
          this.scoreError = this.$t("tests.pleaseEnter") + " 1~7";
        } else {
          this.scoreError = "";
        }
      }
    }
  },
  watch: {
    lang() {
      this.$refs["form"].clearValidate();
    }
  }
};
