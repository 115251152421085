<template>
  <el-form :model="item">
    <el-row :gutter="20">
      <el-col :md="3">
        <el-form-item>&nbsp;</el-form-item>
      </el-col>

      <el-col :md="8">
        <el-select
          v-model="test.items.find(x => x.id === pushable_id).subject"
          filterable
          @change="emitToParent"
          placeholder="Subject"
          style="width:100%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :md="8">
        <el-form-item label="" :error="scoreError">
          <el-input
            v-model="test.items.find(x => x.id === pushable_id).score"
            placeholder="Score"
            @input="emitToParent"
            @blur="checkScoreError(test, pushable_id)"
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :md="5">
        <el-button
          @click="delRow(pushable_id)"
          icon="el-icon-minus"
          plain
        ></el-button>
      </el-col>
    </el-row>

    <el-row :gutter="20" v-if="!isTeacher">
      <!-- <el-col :md="2" class="d-none d-md-block">
        <el-form-item></el-form-item>
      </el-col> -->

      <el-col :span="14" :sm="8" :md="12">
        <el-form-item label="Is this a real test?">
          <el-select
            v-model="test.items.find(x => x.id === pushable_id).is_real_test"
            @change="emitToParent"
            placeholder="Please select"
          >
            <el-option
              v-for="item in isRealTestOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="10" :sm="8" :md="12">
        <el-form-item label="Date of test">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="test.items.find(x => x.id === pushable_id).taken_on"
            type="date"
            @change="emitToParent"
            placeholder="Select the date"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import subjectsData from "@/data/subjects.json";
import testValidationRules from "@/mixins/testValidationRules";
export default {
  props: ["item", "test", "pushable_id", "isTeacher"],
  mixins: [testValidationRules],
  data: function() {
    return {
      scoreError: "",
      isRealTestOptions: [
        {
          value: 1,
          label: "Yes"
        },
        {
          value: 0,
          label: "No"
        }
      ],
      options: this.getOptions()
    };
  },
  methods: {
    getOptions() {
      const subjects = subjectsData.find(obj => {
        return obj.label === this.test.value;
      });
      return subjects.options;
    },
    delRow(id) {
      this.$emit("remove", id);
    },
    emitToParent() {
      this.$emit("dataFromChild", this.item);
    }
  }
};
</script>

<style scoped></style>
